<script>
  import * as _ from 'lodash';
  import { onDestroy, onMount } from 'svelte';
  export let value = '';
  export let placeholder = '';
  export let can_submit = false;
  export let dispatch = () => {};
  
  $: isActive = can_submit || value.length > 0;
  let currentHeight = 0;

  onMount(async () => {
		setupListeners();
	});

  onDestroy(async () => {
    const old_element = document.getElementById("cm-taggable-textarea")
    var new_element = old_element.cloneNode(true);
    old_element.parentNode.replaceChild(new_element, old_element);
  })

  function resize({ target }) {
    target.style.height = 'auto';
    target.style.height = _.min([+target.scrollHeight, 172]) + 'px';
    currentHeight = _.min([+target.scrollHeight, 172]);
  }

  function text_area_resize(el) {
    el.addEventListener('input', resize);
    resize( { target: el });

    return {
      destroy: () => el.removeEventListener('input', resize),
    };
  }

  function setupListeners() {
    document.getElementById("cm-taggable-textarea").addEventListener("input", (e) => {
        value = e.target.innerHTML;
        dispatch('input',{value});
        dispatch('value', {value});
    }, false);
    document.getElementById("cm-taggable-textarea").addEventListener("blur", (e) => {
        value = e.target.innerHTML;
        dispatch('blur',{value});
    }, false);
    document.getElementById("cm-taggable-textarea").addEventListener("value", (e) => {
        value = e.target.innerHTML;
        dispatch('value',{value});
    }, false);
    document.getElementById("cm-taggable-textarea").addEventListener("focus", (e) => {
        value = e.target.innerHTML;
        dispatch('focus',{value});
    }, false);
  }

  
</script>

<div
  class="cm-Position--relative cm-InputMessageTextArea"
  class:cm-Input--InputMessageTextArea--large={currentHeight > 32}>
  <span
    cm-color="Neutral / Storm Grey"
    class:is-active={isActive}
    on:click={() => {
      setTimeout(() => {
        isActive && dispatch('submit', { value });
      });
    }}
    class="cm-Position--bottom-right cm-InputMessageTextArea-messageSubmitIcon">
    <svg
      aria-hidden="true"
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512">
      <path
        fill="currentColor"
        d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z" />
    </svg>
  </span>
  <div 
    id="cm-taggable-textarea"
    contenteditable="true" 
    class="cm-Input cm-Input--textArea"
    class:cm-Input--textArea--large={currentHeight > 32}
  >
  </div>
</div>
